<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  size="mini" placeholder="问题关键字" label="问题关键字" clearable></el-input>
        <el-button type="primary"
                   size="mini" @click="dosearch()" icon="el-icon-search" :disabled="issearch" :loading="issearch">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary"
                   size="mini" @click="add('form')" icon="el-icon-plus">新增问题</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="80">
        </el-table-column>
        <el-table-column
            label="问题标题"
            prop="title"
            min-width="120">
        </el-table-column>
        <el-table-column
            label="显示顺序"
            prop="ordid"
            width="100">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="120" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row,'status')">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.created_at')"
            width="170" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="135" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="edit(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" v-if="!scope.row.is_system" @click="del(scope.row)">{{ $t('global.delete') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.id?'修改问题':'新增问题'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w60">
      <el-form
          size="mini" :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item label="问题标题" label-width="120px" prop="title" required>
          <el-input v-model="noticeformdata.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序" label-width="120px" prop="ordid" required placeholder="数值越大越靠前">
          <el-input v-model="noticeformdata.ordid" class="iw-lg" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('news.status')" label-width="120px" required>
          <el-switch v-model="noticeformdata.status" :active-value="1" :inactive-value="0"
                     :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="问题答案" label-width="120px" prop="info">
          <vue-ueditor-wrap v-model="noticeformdata.info" :config="myConfig"></vue-ueditor-wrap>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            size="mini" @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary"
                   size="mini" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script type="text/javascript">
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'news',
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        title: '',
        info: '',
        ordid: 0,
        status: 1,
      },
      noticeformdata: {},
      rules: {
        title: [
          {min: 2, message: '问题标题必须在2个字符以上', trigger: 'blur'}
        ],
      },
      isshowdialog: false,
      issearch: false,
      isbusy: false,
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=question',
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        serverUrl: process.env.VUE_APP_API_ROOT+'/system/upload/uploadueditor?storage=ueditor',
      }
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async edit(item) {
      this.noticeformdata = {};
      this.isshowdialog = true;
      this.$nextTick(()=>{
        this.noticeformdata = item;
      });
    },
    add(formName) {
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.issearch = true;
      let res = await this.httpget('/system/question/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.issearch = false;
      } else {
        this.issearch = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/question/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item,field) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/question/setenable', {id: item.id,field:field});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            if( field == 'status' ){
              this.list[idx].status = Math.abs(this.list[idx].status - 1);
            } else if( field == 'is_index' ) {
              this.list[idx].is_index = Math.abs(this.list[idx].is_index - 1);
            } else {
              this.list[idx].is_top = Math.abs(this.list[idx].is_top - 1);
            }
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async del(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/question/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    }
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
